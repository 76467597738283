.Form {
    font-family: Raleway;
    height: 100%;
}

.formBox {
    height: 100%;
    padding-bottom: 3vh;
    background-color: #fbfaf2;
    margin-left: 22.5vw;
    margin-right: 22.5vw;
    box-shadow: 1.5vh 1.5vh #60676b;
}

.submitButton {
    background-color: white;
    color: #a6835c;
    border: 1px solid #a6835c;
    font-family: Raleway;
    font-size: 20px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #a6835c;
    --bs-btn-border-color: #a6835c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #a6835c;
    --bs-btn-hover-border-color: #a6835c;
    --bs-btn-focus-shadow-rgb: #a6835c;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #a6835c;
    --bs-btn-active-border-color: #a6835c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #a6835c;
    --bs-btn-disabled-border-color: #a6835c;
}

.submitButton:hover {
    background-color: #fbfaf2;
    border: none;
    color: black;
}

.submitButton:focus {
    background-color: #fbfaf2;
    border: none;
    color: #a6835c;
}

.submitButton:active {
    -webkit-tap-highlight-color: #fbfaf2;
    background-color: #fbfaf2;
    border: none;
    color: black;
}

@media only screen and (max-width:640px) {
    .formBox {
      margin-left: 2.5vw;
      margin-right: 2.5vw;
      box-shadow: none;
    }
  }


.formInnerBox {
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 20px;
    font-family: Raleway;
}

#contactMe {
    padding-left: 5vh;
    padding-top: 5vh;
    margin-bottom: 2.5vh;
    font-size: calc(36px + 0.390625vw);
}

.inputField {
    border-radius: 5px;
    font-family: Raleway;
    height: 2.5vh;
    font-size: 16px;
    margin-top: 5px;
    width: 33% !important;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #f4f4f4;
}

.formText {
    margin-top: 2vh;
}