.Header2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.MakeupBy2 {
  font-family: Alice;
  font-size: 38px;
}
.Sydney2 {
  font-family: Corinthia;
  font-size: 240px;
}

.centerBox2 {
  margin-top: 21vh;
  text-align: center;
  color: #a6835c;
}

.Sydney2 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-3&color=white);
}


.Header1 {
  min-height: 90vh;
  background-color: #e6cfc5;
}

.Makeup1{
padding-top: 3.5%;
color:#FFFFFF;
text-align: center;
font-family: "Italianno";
font-size: 200px;
text-decoration: underline;
}
.by1{
color: #FFFFFF;
text-align: center;
font-family: "Italianno";
font-size: 150px;
text-decoration: underline;
}
.sydney1{
color: #FFFFFF;
text-align: center;
font-family: "Italianno";
font-size: 200px;
text-decoration: underline;
}

.contactMeButton {
  position: absolute;
  right: 10vw;
  background-color: transparent;
  color: #a6835c;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: revert;
  padding-bottom: 5px;
  font-family: Alice;
  font-size: 22px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #a6835c;
    --bs-btn-border-color: #a6835c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #a6835c;
    --bs-btn-hover-border-color: #a6835c;
    --bs-btn-focus-shadow-rgb: #a6835c;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #a6835c;
    --bs-btn-active-border-color: #a6835c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #a6835c;
    --bs-btn-disabled-border-color: #a6835c;
}

.contactMeButton:hover {
  background-color: #fbfaf2;
  border: none;
  color: black;
}

.contactMeButton:focus {
  background-color: #fbfaf2;
  border: none;
  color: #a6835c;
}

.contactMeButton:active {
  -webkit-tap-highlight-color: #fbfaf2;
  background-color: #fbfaf2;
  border: none;
  color: black;
}

@media only screen and (max-width:600px) {

  .MakeupBy2 {
    font-size: 24px;
  }
  .Sydney2 {
    font-size: 86px;
  }
  .Makeup1 {
    font-size: 70px;
  }
  .by1 {
    font-size: 45px;
  }
  .sydney1 {
    font-size: 70px;
  }
  .Header2 {
    min-height: 80vh;
  }
  
  .contactMeButton {
    font-size: 18px;
  }
}

