.Info {
  font-family: Raleway;
  min-height: 70vh;
}

.tabContainer {
  margin-left: 10vw;
  margin-right:10vw;
  margin-top: 10vh;
  margin-bottom: 2vh;

  background-color: #F2EAE4;
  padding-bottom: 3vh;
  min-height: 60vh;
  border-radius: 15px;
}


Figure{
  font-size: 25px;
}

.yikes {
  margin: 4vh 4vw;
  color: black;
}

.nav-item, .nav-fill, .nav-link {
  font-size: 18px;
  height: 5vh;
  color: #a6835c;
}

.nav-link:hover {
  color: black;
}

.nav-item, .nav-fill, .nav-link {
  border-bottom: none !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #F2EAE4;
  border-bottom: none;
}
.imahe {
  float: right;
  height: 45vh;
  width: auto;
  margin-right: 10vh;
  margin-top: 5vh;
  margin-left: 10vh;
}

.agree {
  margin: 10vh 5vw;
}

.accordion-item, .accordion-button {
  min-height: 7.5vh;
  background-color: transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  --bs-accordion-active-bg: none;
  font-size: 20px;
}

.accordion-button:focus {
  background-color: transparent;
  outline: none;
  box-shadow: none;
}

.accordion-button:active {
  background-color: transparent;
  outline: none;
}

.accordion-button:hover {
  background-color: transparent;
  outline: none;
}

.accordion-body {
  padding-left: 4vw;
  font-size: 17px;
}

.card-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 2vh;
}

.note {
  margin-top: 10vh;
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;

}

.noteHeader {
  font-weight: 600;
}

.noteHeaderTXT {
  border-bottom: 0.5px solid black;
}

.card-body {
  min-height: 30vh;
}

.leftText {
  font-weight: 600;
}

.rightText {
  font-style: italic;
}

.lashes {
  font-size: 14px;
  margin-left: 0.5vw;
}

.card {
  background-color: transparent;
  border: none;
}

.customRow {
  display: flex;
  justify-content: space-evenly;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 1.5vh;
}

.middleCard {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 0;
  padding-left: 2vw;
}

.beautyContent {
  padding: 5vh 10vw;
}
.figure-caption {
  font-size: 18px;
}

@media only screen and (max-width:640px) {
  .tabPane {
    display: inline;
  }
  .nav-item, .nav-fill, .nav-link {
    height: 5vh;
  }
  .tabContainer {
    margin: 2vh 2vw;
    box-shadow: none;
  }
  .figure-caption {
    font-size: 18px;
  }

  .middleCard {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: none;
    border-right: none;

    border-radius: 0;
    padding-left: 2vw;
  }
  .imahe {
    margin: 0;
    float: none;
  }
  .figure {
    text-align: center;
  }
}
