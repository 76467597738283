.spacer {
    min-height: 10vh;
}

.footerBox {
    max-height: 22.5vh;
    text-align: center;
    background-color: white;
}

.followMe {
    font-family: Corinthia;
    font-size: 55px;
    padding-top: 2vh;
}

a {
    color: inherit;
    text-decoration: none;
}

.icons {
    display: flex;
    justify-content: space-evenly;
    width: 10vw;
    margin: auto;
}

.footerName {
    margin-top: 2vh;
    padding-bottom: 2vh;
    font-size: 18px;
    font-family: Raleway;
}
@media only screen and (max-width:600px) {
    .icons {
        width: 40vw;
    }

    .footerName {
        margin-top: 3vh;
        padding-bottom: 0;
    }
}